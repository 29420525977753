import React, { useEffect, useState } from "react";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { BlogApi } from "../../Api/BlogApi";
import ErrorMessage from "../../Atoms/ErrorMessage";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import cx from "classnames";
import GlowingPicture from "../../Atoms/GlowingPicture";
import dayjs from "dayjs";

import { Passers } from "prop-passer";

import Modal from "../../Atoms/Modal/Modal";

import { useForm } from "react-hook-form";
import Form from "../../Atoms/Form";
import Input from "../../Atoms/Input/Input";
import Button from "../../Atoms/Button/Button";
import Swal from "sweetalert2";
import ReactWhatsapp from "react-whatsapp";
import Styles from "../../Constants/styles";
import YogaSun from "../../Graphics/YogaSun";
import useIsMobile from "../../Hooks/useMobile";
import { UserApi } from "../../Api/UserApi";
import "../../Components/CourseDetailsPage/style.css";
import Faq from "../Storefront/FAQ";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import MonthlyClasses from "../Storefront/MonthlyBeginnerFilter";
import { ViewAllComponent } from "../Storefront/Home";

export default function BlogPage(props) {
  const isMobile = useIsMobile();
  const history = useHistory();
  const { register, handleSubmit } = useForm({});
  let countryCode = "";

  const { callApi, loading, errorMessage, data } = useApi(BlogApi.blogById);
  const { search } = useLocation();

  const blogData = data?.data;

  const [showModal, setShowModal] = useState(false);

  const title = `Hi. Check out this yoga Blog. Doing yoga regularly can transform you.`;

  const size = "2.5rem";

  const url = window.location.href;

  const ShareList = Passers({
    url: url,
    className: "network__share-button",
  })({
    className: "network cursor-pointer hover transition--default",
  })("li");

  useEffect(() => {
    let searchText = new URLSearchParams(search);

    console.log("props", props);

    let arr = props?.match?.params?.id
      ? props?.match?.params?.id.split("-")
      : props?.match?.params?.name
      ? props?.match?.params?.name.split("-")
      : [];

    const id = searchText.get("name")
      ? searchText.get("name")
      : arr[arr.length - 1];

    callApi(id);
  }, [callApi]);

  useEffect(()=>{
    if(props?.match?.params?.name && props?.match?.params?.name === "yoga-practices"){
      history.push("/not-found")
    }
  },[props?.match?.params?.name])

  const { callApi: createUserApi } = useApi(UserApi.createTherapyUser);

  if (window.COUNTRY_CODE) {
    countryCode = window.COUNTRY_CODE;
  }

  function onSubmit(data) {
    createUserApi({ ...data, course: "Home" })
      .then((res) => {
        countryCode === "IN"
          ? window.open("https://rzp.io/l/B2G3CH3", "_blank")
          : window.open("https://buy.stripe.com/5kAbLl8aw5vC3ao5kk", "_blank");
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Sorry",
          text: err?.data?.message
            ? err?.data?.message
            : "Something went wrong!",
        });
      });
  }

  if (loading) {
    return <div class="loading">Loading&#8230;</div>;
  }

  if (errorMessage) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  if (!blogData) {
    return null;
  }

  const titleText = blogData.title;

  const text = titleText.includes("Pranayama Mantra")
    ? "Pranayama Mantra"
    : titleText.includes("Yoga Onlines Classes")
    ? "yoga online classes for beginners"
    : titleText.includes("Prana Asana")
    ? "Prana Asana"
    : titleText;

  const desc = titleText.includes("Pranayama Mantra")
    ? " Discover the incredible benefits of incorporating Pranayama Mantra into your daily routine! Perfect for beginners, this powerful practice enhances overall well-being, reduces stress, improves focus, and promotes a deep sense of inner peace. Unlock the transformative potential of Pranayama Mantra and experience a harmonious balance between mind, body, and spirit."
    : titleText;

  return (
    <>
      <Helmet>
        <title>{text}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content={text}></meta>
        <meta name="description" content={desc} />
        <meta property="og:title" content={text}></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="og:image" content={blogData.image}></meta>
        <meta property="og:description" content={desc}></meta>
        <meta property="twitter:title" content={text}></meta>
        <meta property="twitter:description" content={desc}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
        <meta property="twitter:image" content={blogData.image}></meta>
      </Helmet>
      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(!showModal);
        }}
        className="sm:w-full sm:max-w-md"
      >
        <div className="flex flex-wrap p-10">
          <div>
            <WhatsappShareButton
              url={url}
              title={title}
              separator="     "
              className="m-5"
            >
              <WhatsappIcon size={size} />
            </WhatsappShareButton>
          </div>
          <div>
            <ShareList>
              <FacebookShareButton quote={title} className="m-5">
                <FacebookIcon size={size} />
              </FacebookShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <TwitterShareButton title={title} className="m-5">
                <TwitterIcon size={size} />
              </TwitterShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <LinkedinShareButton title={title} className="m-5">
                <LinkedinIcon size={size} />
              </LinkedinShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <TelegramShareButton title={title} className="m-5">
                <TelegramIcon size={size} />
              </TelegramShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <EmailShareButton subject={title} body={title} className="m-5">
                <EmailIcon size={size} />
              </EmailShareButton>
            </ShareList>
          </div>
          <div></div>
          <div></div>
        </div>
      </Modal>

      <div className="bg-white p-10 mt-10">
        <div className="mx-0 mt-0 items-center  space-y-2 sm:space-y-0">
          <div className="">
            <div className="w-full" style={{ width: "100%" }}>
              {blogData.image && (
                <img
                  className="header-image"
                  src={blogData.image}
                  alt="cover"
                />
              )}
            </div>
            <div className="text-base sm:text-2xl pt-4 mt-4 font-bold">
              {blogData.title}
            </div>
          </div>

          <div
            className="p-5 mr-0 px-0 lg:rounded-md flex items-center justify-between"
            style={{ paddingRight: 0 }}
          >
            <div>
              <div>
                <div className="flex-col md:flex-row items-center">
                  <div className="flex items-center ">
                    <div>
                      {blogData?.teacher?.profile_picture ? (
                        <GlowingPicture
                          imgUrl={blogData?.teacher?.profile_picture}
                        />
                      ) : (
                        <GlowingPicture imgUrl={""} />
                      )}
                    </div>
                    <div className="font-xs pl-3 pr-5 whitespace-nowrap">
                      <div>{blogData?.teacher?.full_name}</div>
                      <div style={{ fontSize: 12, color: "grey  " }}>
                        {dayjs(blogData.created_at).format("DD-MMM-YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className={cx(
                  "w-full mt-0 focus:outline-none",
                  `tracking-wide inline-block py-2 px-2 rounded shadow-md text-center font-semibold text-white text-sm`,
                  "cursor-pointer text-y-yellow-dark flex items-center justify-center"
                )}
                style={{ border: "1px solid" }}
                onClick={() => {
                  setShowModal(!showModal);
                }}
              >
                <img
                  src="/img/share.png"
                  height={20}
                  width={20}
                  alt="whatspp"
                  style={{ marginRight: 0 }}
                />
              </button>
            </div>
          </div>

          {blogData.description && (
            <div
              className="pt-5"
              dangerouslySetInnerHTML={{
                __html: blogData.description.replaceAll(
                  "<p></p>",
                  `<p style="padding:15px 0;"></p>`
                ),
              }}
            ></div>
          )}
        </div>
      </div>
      <div>
        <h2 className="pt-7 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-10">
          Monthly yoga courses
        </h2>
        <p
          className="pt-1 pb-4 text-gray-600 text-base font-bold"
          style={{ fontSize: "14px" }}
        >
          A regular practice helps you progress.
        </p>

        <MonthlyClasses
          filterArr={{
            levels: [],
            price: [],
            healthConditions: [],
            time: [],
            style: [],
            sort: [],
            queryText: "",
            type: "month",
          }}
          name="month"
          typeData="month"
          showTime={true}
        />

        <ViewAllComponent
          link="/courses/monthly-classes"
          query="?name=&type=month"
          name="Monthly Yoga Courses"
          bannerText={[
            "A regular practice helps you progress.",
            "Find a monthly yoga class that works for you.",
          ]}
        />
      </div>

      <>
        <div class="mt-10">
          <h2 className="pt-0 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0 pb-4">
            FAQs - Frequently Asked Questions
          </h2>
          <Faq />
        </div>

        <div class="mt-10">
          <h2 className="mt-2 text-y-yellow-dark text-lg md:text-xl font-bold mt-0">
            Tell us your details and book your first yoga session with us.
          </h2>
          <p
            className="pt-1 pb-4 text-black text-base font-bold"
            style={{ fontSize: "14px" }}
          >
            Our yoga counselor will reach you to understand your needs and
            assign the best yoga class for you.
          </p>
          <div className="w-full md:w-2/5 pt-3">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="">
                <Input
                  required
                  name="name"
                  label="Name"
                  inputRef={register({ required: true })}
                  className=""
                />
              </div>

              <div className="">
                <Input
                  required
                  name="phone"
                  label="Phone"
                  inputRef={register({ required: true })}
                />
              </div>

              <div className="flex pt-0">
                <Button py="py-3" className="whitespace-nowrap" type="submit">
                  Submit & Book Trial
                </Button>
              </div>
            </Form>
          </div>
        </div>

        <div class="mb-10 mt-10">
          <div
            className={cx(
              "px-0 flex flex-row md:flex-row-reverse justify-between items-center overflow-x-auto sm:mt-10",
              "sm:h-full sm:w-4/5"
            )}
          >
            <div className="join-live-class text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-5 sm:w-2/4">
              <p className="mt-2 text-y-yellow-dark">
                Talk to our yoga counselors.
                <br />
                <br />
                We are here to help you.
                <br />
                <br />
              </p>
              {/* <p
            className="sm:block mt-2 text-black text-base"
            style={{ fontSize: "14px" }}
          >
            Tell us about yourself and let us guide you to the right yoga class.
          </p> */}
              <div className="flex">
                <ReactWhatsapp
                  number="+917975322831"
                  message={`Hi, I want to do yoga. About me - `}
                  className=""
                >
                  <button
                    className={cx(
                      "w-full mt-5 focus:outline-none",
                      `tracking-wide inline-block py-3 px-8 rounded shadow-md text-center font-semibold text-white text-sm`,
                      "cursor-pointer text-white flex items-center justify-center"
                    )}
                    style={{
                      ...Styles.OrangeLinearGradient,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <img
                      src="/img/whatsapp.png"
                      height={40}
                      width={40}
                      alt="whatspp"
                      style={{ marginRight: 5 }}
                    />
                    Whatsapp Us
                  </button>
                </ReactWhatsapp>
              </div>
            </div>
            <div>
              <YogaSun
                width={!isMobile ? 230 : 353}
                height={!isMobile ? 100 : 301}
              />
            </div>
          </div>
        </div>
      </>
    </>
  );
}
